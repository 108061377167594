import React from 'react'
import Layout from '../components/Layout'
import SocialSideBar from '../components/SocialSideBar'
import styles from './we.module.scss'
import David1Img from '../images/png/we_david-1.png'
import LindaImg from '../images/png/we_linda.png'
import David2Img from '../images/png/we_david-2.png'
import TimImg from '../images/png/we_tim.png'
import JeffImg from '../images/png/we_jeff.png'
import NolanImg from '../images/png/we_nolan.png'
import MaheshImg from '../images/png/we_mahesh.png'
import HernanImg from '../images/png/we_hernan.png'

const We = () => {
  return (
    <Layout>
      <section className={styles.sectionOne}>
        <SocialSideBar />
        <div className={styles.imageContainer}>
          <div className={styles.textContainer}>
            <h2>¡Transformando</h2>
            <h2>el mundo de la</h2>
            <h2>enseñanza en idiomas!</h2>
            <h4>
              Transformando el aprendizaje digital de inglés a través de la
              poderosa combinación del entrenamiento de lenguaje 3-D para
              móviles y computadoras.
            </h4>
          </div>
        </div>
      </section>
      <section className={styles.sectionTwo}>
        <h1>¡Un sistema de educación inmersiva que recordarás!</h1>
        <h4>
          Nuestros innovadores metodos de enseñanza de idiomas en 3D
          potencializan el poder de presencia y interacción en niveles simulados
          de un “mundo real”, creando un nuevo modelo transformativo por
          computadora y móviles para enseñanza de idiomas más atrayente,
          interactivo y efectivo para aprender que otras soluciones digitales
          para el aprendizaje de idiomas.
        </h4>
      </section>
      <section className={styles.sectionThree}>
        <h1>Por ellos esto es posible</h1>
        <h4>
          Conoce los perfiles profesionales de{' '}
          <span>nuestro equipo de trabajo</span>
        </h4>
        <div className={styles.aboutUsContainer}>
          <div className={styles.singlePerson}>
            <img src={David1Img} alt="David Bradford" />
            <h3>David Bradford</h3>
            <p>
              Graduado con honores en la Universidad de BYU en Provo Utah. David
              ha sido CEO de varias compañías lideres mundiales en tecnología.
              David mantiene una estrecha relación con personas que han
              revolucionado las telecomunicaciones en el mundo.
            </p>
            <br />
            <p>
              Su decisión de desarrollar la tecnología 3D ``Voice Recognition``
              de FluentWorlds® ha sido el paso mas importante de su carrera.
            </p>
          </div>
          <div className={styles.singlePerson}>
            <img src={LindaImg} alt="Linda Bradford" />
            <h3>Linda Bradford</h3>
            <p>
              La Dra. Linda Bradford tiene un PhD en Instructional Technology y
              es miembro del Salón de la Fama de la Tecnología de Utah.
            </p>
            <br />
            <p>
              Linda fue CEO de una de las 50 compañías tecnológicas de más
              rápido crecimiento en los Estados Unidos.
            </p>
            <br />
            <p>
              Linda recluto a Sr. Steve Wozniak inventor de la tecnología de
              Apple quien posteriormente se unió a su equipo en Fusion- io.
            </p>
          </div>
          <div className={styles.singlePerson}>
            <img src={David2Img} alt="David E. Bradford" />
            <h3>David E. Bradford</h3>
            <p>
              David Eric es un abogado graduado en la Universidad de BYU y fue
              co-fundador de FluentWorlds.
            </p>
            <br />
            <p>Actualmente desempeña el cargo de VP de Mercadeo.</p>
            <br />
            <p>
              En su carrera David Eric fue CEO de CreoVirtus Consulting, Gerente
              de Producto de Walmart en la casa matriz en Bentonville Arkansas,
              trabajo para Hewlett Packard y estuvo vinculado como gerente de
              campo de operaciones políticas en la campaña de Mitt Romney para
              gobernador del estado de Utah.
            </p>
          </div>
          <div className={styles.singlePerson}>
            <img src={TimImg} alt="Tim Doner" />
            <h3>Tim Doner</h3>
            <p>
              Tim es el Poliglota mas conocido en el mundo gracias a su
              capacidad de hablar 23 idiomas.
            </p>
            <br />
            <p>
              A los 22 años de edad Tim se graduó en Lenguajes de civilizaciones
              de Oriente y ciencias Gubernamentales en la Universidad de
              Harvard.
            </p>
            <br />
            <p>
              Actualmente esta finalizando un PHD en Relaciones Internacionales
              y ciencias políticas en la Universidad de Cambridge en Londres.
            </p>
          </div>
          <div className={styles.singlePerson}>
            <img src={JeffImg} alt="Jeff Adams" />
            <h3>Jeff Adams</h3>
            <p>
              Graduado en la Universidad de Berkley, la University of California
              y la University of Oregon Jeff ha contribuido al desarrollo de
              nuevas tecnologías como Siri y Amazon Echo (alexa), Jeff es autor
              de 21 patentes mundiales de reconocimiento de voz y lenguaje.
            </p>
            <br />
            <p>
              Jeff ha sido CEO de empresas como Cobalt Speech & Language, Wicked
              smart speech, OmniBot.ai, y Conversational Ai platform solutions.
            </p>
          </div>
          <div className={styles.singlePerson}>
            <img src={NolanImg} alt="Nolan Bushnell" />
            <h3>Nolan Bushnell</h3>
            <p>
              Ingeniero de la Universidad de Stanford y de la Universidad de
              Utah, Nolan es considerado a nivel mundial como el Padre de las
              tecnologías de la industria de juegos de video.
            </p>
            <br />
            <p>Nolan fue fundador y CEO de Atari y otras compañías como:</p>
            <br />
            <p>X2 Blockchain Games HearGlass Inc. BrainRush Inc.</p>
          </div>
          <div className={styles.singlePerson}>
            <img src={MaheshImg} alt="Mahesh Ram" />
            <h3>Mahesh Ram</h3>
            <p>
              Mahesh fue fundador y CEO de Solvvy así como CEO de Global
              English.
            </p>
            <br />
            <p>Fue consejero de la empresa Name LearnBIG.</p>
            <br />
            <p>
              La contribución de Mahesh para el desarrollo global de
              FluentWorlds® ha sido extraordinaria por su amplia experiencia
              previa trabajando con empresas globales dedicadas a la enseñanza
              tradicional del Ingles.
            </p>
          </div>
          <div className={styles.singlePerson}>
            <img src={HernanImg} alt="Hernan Ordoñez" />
            <h3>Hernan Ordoñez</h3>
            <p>
              Hernan es nuestro consejero bilingue para el desarrollo de
              negocios de FluentWorlds® en Latino América.
            </p>
            <br />
            <p>
              Hernan ha desempeñado cargos ejecutivos en varias compañías
              multinacionales de Norte América, Latino América y Asia.
            </p>
            <br />
            <p>
              Las conexiones de Hernan con compañías y empresarios en el mundo
              hispano es un factor clave para el crecimiento de FluentWorlds® en
              Latino América.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default We
